import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
  wrapper: {
    backgroundColor: '#F3F4F6',
    maxHeight: 0,
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      duration: transitions.duration.shortest,
      easing: transitions.easing.easeInOut,
    }),
  },
  filter: {
    '& .MuiGrid-item': {
      width: '20%',
      maxWidth: '20%'
    }
  },
  wrapperOpened: {
    maxHeight: 180,
    [breakpoints.down('xs')]: {
      maxHeight: 520,
    },
  },
  searchButton: {
    float: 'right',
  },
  inputFakeDate: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    color: '#6C757D',
    height: 38,
    fontSize: 14,
    paddingLeft: 5,
    cursor: 'pointer',
    alignItems: 'center',
    '&:active': {
      borderColor: '#FF5225',
    },
    [breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  orderStatus: {
		width: '12px',
		height: '12px',
		fontSize: '7px',
		marginRight: '4px',
		borderRadius: '20px',
		'& .MuiBox-root': {
		}
	},
  buttonCleanFilter: {
    '& .MuiButton-label': {
      display: 'flex',
      gap: '5px',
    },

    '& svg path': {
      fill: '#F4282D'
    },

    '& .MuiTypography-body1': {
      display: 'flex',
      alignItems: 'center',
      color: '#F4282D',
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'capitalize',
      height: '32px',
    }
  },
  actionsFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));

export default useStyles;
