const statusMainColorMap = {
  Aberto: '#EAF5FD',
  Aprovado: '#EBFAEF',
  Pendente: '#FFF9E8',
  Reprovado: '#F8ECEC',
  'Aprovado com Entrada': '#EBFAEF',
};

const statusSecondaryColorMap = {
  Aberto: '#1459FF',
  Aprovado: '#21AD34',
  Pendente: '#E09F00',
  Reprovado: '#F4282D',
  'Aprovado com Entrada': '#21AD34',
};

export const getStatusMainColor = (color) => statusMainColorMap[color] || 'grey';

export const getStatusSecondaryColor = (color) => statusSecondaryColorMap[color] || 'grey';
