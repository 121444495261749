import { makeStyles } from '@material-ui/core';
import { FilterSelected, FilterUnselected } from '../../../assets';

const useStyles = makeStyles(() => ({
  titlePagination: {
    '& .MuiTypography-h6': {
      fontSize: 20,
      fontWeight: 600,
    },

    '& .MuiBox-root': {
      height: 64
    },

    '& .MuiTablePagination-toolbar': {
      height: '100%'
    },

    '& .MuiDivider-root': {
      display: 'none',
    },

    '& .MuiTablePagination-selectIcon': {
      fill: '#FE5000'
    }
  },

  icon: {
    padding: 10,
    width: 35,
    height: 35,
    backgroundSize: 'cover',
  },

  iconFilterOpened: {
    backgroundImage: `url(${FilterSelected})`,
  },

  iconFilterClosed: {
    backgroundImage: `url(${FilterUnselected})`,
  },
}));

export default useStyles;
