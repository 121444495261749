import React from 'react';
import {
  Box,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';

import {
  toMoney,
  toPercent
} from '../../../../../../shared/utils/parsers';

import useStyles from './styles';

const statusMainColorMap = {
  UTILIZADO: '#F8F9FA',
  DISPONIVEL: '#EBFAEF',
  VENCIDO: '#F8ECEC',
};

const statusSecondaryColorMap = {
  UTILIZADO: '#7B7F82',
  DISPONIVEL: '#21AD34',
  VENCIDO: '#F4282D',
};

const VouchersList = ({
  vouchers
}) => {
  const {
    voucherContainer,
    voucherInfo,
    voucherCopy,
    voucherChip,
    voucherGrid,
    voucherTitle,
  } = useStyles();

  const getStatusMainColor = (color) => statusMainColorMap[color] || 'grey';
	const getStatusSecondaryColor = (color) => statusSecondaryColorMap[color] || 'grey';

  const getVoucherStatus = (status) => {
    if (status === 'DISPONIVEL') status = 'Disponível';
    if (status === 'UTILIZADO') status = 'Utilizado';
    if (status === 'VENCIDO') status = 'Vencido';

    return status;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Typography className={voucherTitle}>Vale Gerado</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={voucherTitle}>Gerado em</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={voucherTitle}>Desconto</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={voucherTitle}>Válido até</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={voucherTitle}>Situação</Typography>
        </Grid>
      </Grid>
      <Box className={voucherContainer}>
        {vouchers.map((vale) => {
          if (!vale) return (
            <Box display="flex" justifyContent="center" alignItems="center" height="80px">
              <Typography fontSize={12} fontWeight="bold" color="primary">Nenhum vale encontrado</Typography>
            </Box>
          )

          return (
            <>
              <Grid container key={vale.idVale} className={voucherGrid}>
                <Grid className={voucherCopy} item xs={4}>
                  <Typography className={voucherInfo}>{vale.idVale}</Typography>
                  {/* <Button
                    color="primary"
                    size="small"
                    variant="text"
                    className={voucherCopyButton}
                  >
                    <div className={copyIcon} />
                    Copiar
                  </Button> */}
                </Grid> 
                <Grid item xs={2}>
                  <Typography className={voucherInfo}>{vale.dataCriacao.split(' ')[0]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={`${voucherInfo} bold`}>{
                      vale.tipo === 'MONETARIO'
                    ? toMoney(vale.valor)
                    : toPercent(vale.valor)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={voucherInfo}>{vale.dataValidade.split(' ')[0]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Chip className={voucherChip} label={getVoucherStatus(vale.status)} color="primary" style={
                    {
                      backgroundColor: getStatusMainColor(vale.status),
                      border: '1px solid',
                      borderColor: getStatusSecondaryColor(vale.status),
                      color: getStatusSecondaryColor(vale.status),
                      fontWeight: 500,
                      fontSize: 12,
                      minWidth: '80%',
                    }
                  }/>
                </Grid>
              </Grid>
            </>
          )
        })}
      </Box>
    </>
  );
};


VouchersList.defaultProps = {
  vouchers: {},
};

VouchersList.propTypes = {
  vouchers: PropTypes.array.isRequired,
};

export default VouchersList;
