import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Paper,
  Switch,
  Button,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CardBar from '../../shared/components/cardBar';
import ResponsiveTable from '../../shared/components/responsiveTable';
import devPermition from '../../shared/utils/devPermition';
import useStyles from '../styles';
import { getFuncionalidadeAction, putFuncionalidadeAction } from './actions';

const Page = ({
  loading, funcionalidades, getFuncionalidade, putFuncionalidade, devAcess,
}) => {
  const classes = useStyles();
  const [valueInput, setValueInput] = useState('');

  const handleInput = (e) => {
    setValueInput(e.target.value);
  };
  useEffect(() => {
    if (!devAcess) {
      window.location.href = process.env.REACT_APP_HOST_CATALAGO;
    } else {
      getFuncionalidade();
    }
  }, []);

  const handlePutFuncionalidade = (id, value) => {
    putFuncionalidade({
      id,
      ativar: value !== undefined ? value : valueInput,
    });
  };

  return (
    <div>
      <CardBar title="Home" />
      {loading && (<LinearProgress />)}
      <section className={classes.sessionTable}>
        <>
          <Paper elevation={1} style={{ width: '95%', margin: 32 }}>
            <ResponsiveTable>
              <TableHead style={{ backgroundColor: '#F9F9F9' }}>
                <TableRow>
                  <TableCell component="th" align="left">
                    Funcionalidade
                  </TableCell>
                  <TableCell component="th" align="right">
                    Ação
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {funcionalidades.map((item) => (
                  <TableRow
                    key={item.id}
                  >
                    <TableCell datatype="Funcionalidade" align="left">
                      {item.funcionalidade}
                    </TableCell>
                    {((item.valor !== 'FALSE') && (item.valor !== 'TRUE'))
                      ? (
                        <TableCell datatype="Ação" align="right">
                          <TextField
                            defaultValue={item.valor}
                            onChange={(e) => handleInput(e)}
                            size="small"
                            disabled={loading}
                            style={{ width: 90, textTransform: 'capitalize' }}
                            variant="outlined"
                          />
                          <Button
                            disabled={loading}
                            style={{ width: 100, height: 35 }}
                            color="primary"
                            onClick={handlePutFuncionalidade(item.id)}
                          >
                            Salvar
                          </Button>
                        </TableCell>
                      )
                      : (
                        <TableCell datatype="Ação" align="right">
                          <Switch
                            checked={item.valor !== 'FALSE'}
                            onChange={(e) => handlePutFuncionalidade(item.id,
                              e.target.checked)}
                            color="primary"
                            name="checkedB"
                            disabled={loading || item.funcionalidade === 'Controla Produtos MC Catalogo'}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                      )}

                  </TableRow>
                ))}

              </TableBody>
            </ResponsiveTable>
          </Paper>
        </>

      </section>
    </div>
  );
};

Page.propTypes = {
  loading: PropTypes.bool.isRequired,
  // checkoutMeta: PropTypes.instanceOf(Object).isRequired,
  // clearMeta: PropTypes.func.isRequired,
  // takeCheckout: PropTypes.func.isRequired,
  funcionalidades: PropTypes.instanceOf(Object).isRequired,
  getFuncionalidade: PropTypes.func.isRequired,
  putFuncionalidade: PropTypes.func.isRequired,
  devAcess: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  ...state.main.devAcess,
  ...state.main.app,
  user: state.auth.login.user,
  devAcess: devPermition(state.auth.login.user.idVendedor),
});

export default connect(mapState, {
  getFuncionalidade: getFuncionalidadeAction,
  putFuncionalidade: putFuncionalidadeAction,
})(Page);
