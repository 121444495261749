import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { change } from 'redux-form';

import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Divider,
} from '@material-ui/core';

import ResponsiveTable from '../../shared/components/responsiveTable';

import { getCanalDeVendas } from '../../shared/services/app';
import {
  getListCreditRequestsAction,
  getVendedoresSimplificadoAction,
  setCreditRequestAction,
  toggleFilterAction,
} from './actions';
import { FILTER_SALLES_CHANNEL } from '../../shared/utils/creditRequests';

import TableCellHeaderOrdination from '../../shared/components/tableCellHeaderOrdination/TableCellHeaderOrdination';
import FilterCreditRequest from './filterCreditRequest/FilterCreditRequest';
import TitlePaginationFilter from '../_components/filters/TitlePaginationFilter';

import useStyles from './styles';
import TableCreditRequest from './tableCreditRequest/TableCreditRequest';

const CreditRequestsPage = ({
  rpp,
  user,
  count,
  idUser,
  loading,
  currentPage,
  toggleFilter,
  filterOpened,
  creditRequests,
  getListCreditRequests,
  setCreditRequest,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  console.log('teste')

  const queryParams = new URLSearchParams(window.location.search);
  const solicitacaoId = queryParams.get('solicitacaoId');

  useEffect(() => {
    if (solicitacaoId) dispatch(change('creditRequests/filter', 'idSolicitacao', solicitacaoId));
  }, [solicitacaoId]);

  const [dataInicio, setDataInicio] = useState(moment(new Date()).subtract(1, 'month').format('DD-MM-YYYY'));
  const [dataFim, setDataFim] = useState(moment(new Date()).format('DD-MM-YYYY'));

  const handleChangePage = (_, page) => {
    if (!loading) {
      getListCreditRequests({
        dataInicio, dataFim, page: page + 1, rpp,
      });
    }
  };

  const handleChangeRpp = ({ target }) => {
    if (!loading) {
      getListCreditRequests({
        dataInicio, dataFim, page: 1, rpp: target.value,
      });
    }
  };

  const handleToggleFilter = () => toggleFilter({ filterOpened: !filterOpened });

  const showCanaisDeVenda = FILTER_SALLES_CHANNEL.includes(user.idCargo);

  const getIdCanalVenda = () => (showCanaisDeVenda ? getCanalDeVendas() : '');

	const handleFilter =(dataInicioFilter, dataFimFilter) => {
		if (loading) return;
		const idCanalVendas = getIdCanalVenda();

		getListCreditRequests({
			dataInicio: dataInicioFilter || dataInicio,
			dataFim: dataFimFilter || dataFim,
			page: 1,
			idUser,
			rpp: 10,
			idCanalVendas
		});
	};

  const [ordinationColumn, setOrdinationColumn] = useState('');
  const [ordinationDirection, setOrdinationDirection] = useState('Desc');

  const handleOrdination = (column) => {
    const idCanalVendas = getIdCanalVenda();

    if (column === ordinationColumn) {
      setOrdinationDirection(ordinationDirection === 'Asc' ? 'Desc' : 'Asc');
    } else {
      setOrdinationColumn(column);
      setOrdinationDirection('Asc');
    }

    getListCreditRequests({
      dataInicio, dataFim, page: 1, idUser, rpp: 10, idCanalVendas, ordenacao: ordinationDirection, campoOrdenacao: column,
    });
  };

  useEffect(() => {
    if (loading) return;

    const idCanalVendas = getIdCanalVenda();

    getListCreditRequests({
      dataInicio, dataFim, page: 1, idUser, rpp: 10, idCanalVendas,
    });
  }, [getListCreditRequests]);

  return (
    <section className={classes.pageSection}>
      <TitlePaginationFilter
        rpp={rpp}
        title="Painel de Solicitações de Crédito"
        count={count}
        filterOpened={filterOpened}
        currentPage={currentPage}
        handleChangeRpp={handleChangeRpp}
        handleChangePage={handleChangePage}
        handleToggleFilter={handleToggleFilter}
      />

      <FilterCreditRequest
        open={filterOpened}
        handleFilter={handleFilter}
        onSubmit={handleFilter}
        setFilterDataInicio={setDataInicio}
        setFilterDataFim={setDataFim}
        filterDataInicio={dataInicio}
        filterDataFim={dataFim}
        loading={false}
      />

      <Divider />

      {loading && <LinearProgress />}

      <ResponsiveTable classes={classes.table}>
        <TableHead>
          <TableRow
            className={classes.tableHeadRow}
            classes={{
              root: classes.oddRow,
            }}
          >
            <TableCell className={classes.tableHeadCell} component="th">
              Orçamento
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Id. Solic.
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Dados do Cliente
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Vendedor
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Canal de Vendas
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Status
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Crédito Solicitado
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Pedido
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Entrada
            </TableCell>
            <TableCellHeaderOrdination
              reference="dataSolicitacao"
              column={ordinationColumn}
              ordination={ordinationDirection}
              handleOrdination={handleOrdination}
              component="th"
            >
              Data da Solicitação
            </TableCellHeaderOrdination>
            <TableCell className={classes.tableHeadCell} component="th">
              Data de Atualização
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Detalhes
            </TableCell>
            <TableCell className={classes.tableHeadCell} component="th">
              Ação
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading && (
          <TableRow>
            <TableCell colSpan={12} className={classes.message}>
              Buscando solicitações de desconto...
            </TableCell>
          </TableRow>
          )}

          {!loading && !creditRequests.length && (
          <TableRow>
            <TableCell colSpan={12} className={classes.message}>
              Nenhuma solicitação foi encontrada
            </TableCell>
          </TableRow>
          )}

          {creditRequests.map((item, index) => (
            <TableCreditRequest
              setCreditRequest={setCreditRequest}
              row={item}
              index={index}
            />
          ))}
        </TableBody>
      </ResponsiveTable>
    </section>
  );
};

CreditRequestsPage.propTypes = {
  getListCreditRequests: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  filterOpened: PropTypes.bool.isRequired,
  creditRequests: PropTypes.arrayOf(Object).isRequired,
  currentPage: PropTypes.number.isRequired,
  idUser: PropTypes.number.isRequired,
  totalpages: PropTypes.number.isRequired,
  rpp: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  setCreditRequest: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  ...state.main.creditRequests,
  idUser: state.auth.login.user.idVendedor,
  drawerOpenned: state.main.app.drawer,
  user: state.auth.login.user,
});

export default connect(mapState, {
  getListCreditRequests: getListCreditRequestsAction,
  getVendedoresSimplificado: getVendedoresSimplificadoAction,
  toggleFilter: toggleFilterAction,
  setCreditRequest: setCreditRequestAction,
})(CreditRequestsPage);
