import { makeStyles } from '@material-ui/core';
import { EditIcon } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints }) => ({
  infoContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  titleTypography: {
    fontWeight: 700,
    fontSize: 18,
    color: '#2A2A2A',
  },
  actionButton: {
    height: 32,
    borderRadius: 8,
    textTransform: 'capitalize',
  },
  iconHeader: {
    display: 'flex',
    width: 24,
    height: 24,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',

    '& svg': {
      fill: '#fff',
    },

    '&.edit': {
      background: `url(${EditIcon})`,
    },
  },
  selectField: {
    '&&': {
      alignItems: 'end',
      '& .MuiSelect-root': {
        height: 20,
        fontSize: 14,
        padding: 14,
        border: '1px solid #CFCFD4',
        background: '#FFFFFF',
        width: '250px',
      },

      '& .MuiSvgIcon-root': {
        color: '#FF5225',
        height: 28,
        width: 28,
      },
    }
  }
}));

export default useStyles;
