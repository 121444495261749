import React from "react";
import * as PropTypes from "prop-types";

import useStyles from "./styles";
import { Field } from "redux-form";
import CheckBoxRender from "../../CheckBoxRender";

const CheckboxForm = ({
  name,
  label,
  required,
  className,
  disabled,
  readOnly,
  props
}) => {
  const {
    formControlLabel,
  } = useStyles();

  return (
    <div>
      <Field
        {...props}
        name={name}
        className={className}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        component={CheckBoxRender}
        classes={{root: formControlLabel}}
      />
  </div>
  )
};

CheckboxForm.defaultProps = {
  required: false,
};

CheckboxForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  
};

export default CheckboxForm;
