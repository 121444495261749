import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardBar: {
    height: 64,
    '&. MuiTypography-h6': {
      fontSize: 20,
      fontWeight: 600,
      color: '#FE5000'
    }
  },
  buttonActionPayment: {
    height: 32,
    width: 180,
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: 4,
    marginRight: 8, 
  }
}));

export default useStyles;
