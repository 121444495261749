import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  voucherInfo: {
    '&&': {
      textAlign: 'center',
      fontSize: 12,
      color: '#242424'
    }
  },
  voucherCopyButton: {
    '&&': {
      display: 'flex',
      gap: 8,
      fontSize: 12,
      alignItems: 'center',
      width: '65%',
      textTransform: 'none',
      height: '40px',
      borderRadius: 8,
      paddingRight: 10,
    }
  },
  voucherTitle: {
    '&&': {
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#242424',
    }
  },
  voucherInfo: {
    overflowWrap: 'unset',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    color: '#242424',
    '&.bold':{
      fontWeight: 'bold',
    }
  },
  voucherChip: {

  },
  voucherGrid: {
    '&&': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      height: 70,
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #e0e0e0',
    },
  },
  voucherCopy: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F9FA',
    border: '1px solid #f1f1f1',
    height: '40px',
    borderRadius: 8,
    paddingLeft: '10px',
  },
}));

export default useStyles;
