export const handleSortBy = () => {
  const { REACT_APP_ENV } = process.env;

  if (REACT_APP_ENV === 'prod') {
    return (
      [
        { label: '+ Mais vendidos', value: 'ldm_products_best_selling_score_desc' },
        { label: 'Mais bem avaliados', value: 'ldm_products_review_quantity_desc' },
        { label: 'Preço: do menor para o maior', value: 'ldm_products_price_asc' },
        { label: 'Preço: do maior para o menor', value: 'ldm_products_price_desc' },
        { label: 'MR', value: 'ldm_products_margin_desc' },
      ]
    );
  }
  return (
    [
      { label: '+ Mais vendidos', value: 'ldm_vendasassistidas_hml_best_selling_score_desc' },
      { label: 'Mais bem avaliados', value: 'ldm_vendasassistidas_hml_review_quantity_desc' },
      { label: 'Preço: do menor para o maior', value: 'ldm_vendasassistidas_hml_price_asc' },
      { label: 'Preço: do maior para o menor', value: 'ldm_vendasassistidas_hml_price_desc' },
      { label: 'MR', value: 'ldm_vendasassistidas_hml_margin_desc' },
    ]
  );
};

export const handleCheckboxRefinements = (user, customer) => {
  const checkboxes = [
    {
      content: 'Estoque <b>nesta filial</b>',
      attribute: 'stockEstablishmentsAvailable',
      custom: true,
      user,
    },
    {
      content: 'Frete <b>grátis</b>',
      attribute: 'shippingData.freeShipping',
    },
    {
      content: 'Entrega <b>expressa</b>',
      attribute: 'shippingData.expressDelivery',
    },
  ];

  if (customer) {
    checkboxes.push({
      content: 'Retira <b>Loja</b>',
      attribute: 'stockEstablishmentsAvailable',
      custom: true,
      withdrawStore: true,
      customer,
    });
  }

  checkboxes.push({
    content: 'PPL',
    attribute: 'ppl.pdv',
  });

  return checkboxes;
};

export const handleRefinements = (indexFilialUser) => ([{
  options: [
    {
      refinement: {
        attributes: ['categoryLevels.lvl0', 'categoryLevels.lvl1', 'categoryLevels.lvl2'],
        title: 'Categoria',
        showMore: true,
        hasCollapse: true,
        isHierarchical: true,
        limit: 7,
        showMoreLimit: 150,
      },
    },
    {
      refinement: {
        attribute: 'technicalSpecs.voltage',
        title: 'Tensão',
        hasCollapse: true,
      },
    },
    {
      refinement: {
        attribute: 'brand.name',
        title: 'Marcas',
        titleShowMore: 'marcas',
        hasCollapse: true,
        isSearchable: true,
        showMoreLimit: 150,
        limit: 5,
      },
    },
    {
      refinement: {
        attribute: 'stockEstablishmentsAvailable',
        title: 'Filiais',
        titleShowMore: 'filiais',
        hasCollapse: true,
        isSearchable: true,
        transformItems: true,
        attributeFilial: true,
        showMoreLimit: 150,
        limit: 5,
      },
    },
    {
      refinement: {
        attribute: `${indexFilialUser
          ? (`priceEstablishments.${indexFilialUser}.term`)
          : ('price.term')}`,
        title: 'Preço',
        isNumeric: true,
        hasCollapse: true,
        ranges: [
          { label: 'R$ 0 ~ R$ 20,00', end: 20 },
          { label: 'R$ 20,00 ~ R$ 40,00', start: 20, end: 40 },
          { label: 'R$ 40,00 ~ R$ 220,00', start: 40, end: 220 },
          { label: 'R$ 220,00 ~ R$ 430,00', start: 220, end: 430 },
          { label: 'R$ 430,00 ~ R$ 940,00', start: 430, end: 940 },
          { label: 'R$ 940,00 ~ R$ 1.750,00', start: 940, end: 1750 },
          { label: 'R$ 1.750,00 ~ R$ 19.000,00', start: 1750, end: 19000 },
        ],
      },
    },
    {
      refinement: {
        attribute: 'rankingData.margin',
        title: 'MR',
        isNumeric: true,
        hasCollapse: true,
        ranges: [
          { label: '0 ~ 5', end: 5 },
          { label: '5 ~ 10', start: 5, end: 10 },
          { label: '10 ~ 20', start: 10, end: 20 },
          { label: '+ 20', start: 20.01 },
        ],
      },
    },
    {
      refinement: {
        attribute: 'price.promotionalDiscountPercentage',
        title: 'Desconto',
        isSlider: true,
        hasCollapse: true,
      },
    },
  ],
}]);
