export default {
  GET_CREDIT_REQUESTS_REQUEST: '@credit/GET_CREDIT_REQUESTS_REQUEST',
  GET_CREDIT_REQUESTS_SUCCESS: '@credit/GET_CREDIT_REQUESTS_SUCCESS',
  GET_CREDIT_REQUESTS_FAILURE: '@credit/GET_CREDIT_REQUESTS_FAILURE',

  GET_COMMENTS_REQUEST: '@credit/GET_COMMENTS_REQUEST',
  GET_COMMENTS_SUCCESS: '@credit/GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FAILURE: '@credit/GET_COMMENTS_FAILURE',

  POST_COMMENT_REQUEST: '@credit/POST_COMMENT_REQUEST',
  POST_COMMENT_SUCCESS: '@credit/POST_COMMENT_SUCCESS',
  POST_COMMENT_FAILURE: '@credit/POST_COMMENT_FAILURE',

  SET_CREDIT_REQUESTS: '@credit/SET_CREDIT_REQUESTS',

  TOGGLE_FILTER: '@credit/TOGGLE_FILTER',
};
