import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getFilial } from '../shared/services/app';
import types from './types';
import checkoutsAndOrders from './checkoutsAndOrders/reducer';
import order from './order/reducers';
import checkout from './checkout/reducers';
import creditRequests from './creditRequests/reducer';
import recovery from './recoveryCart/reducers';
import report from './salesReport/reducer';
import discount from './discount/reducers';
import sales from './salesChannel/reducers';
import devAcess from './devAccess/reducers';
import suggestion from './suggestion/reducer';
import clientInfo from './client/reducers';
import clientAddresses from './client/pages/addressesPage/reducers';
import vouchersAndCoupons from './client/pages/vouchersPage/reducers';

const INITIAL_STATE = {
  drawer: false,
  menuRef: null,
  width: window.innerWidth,
  loadingFilials: false,
  filial: getFilial(),
  snack: null,
  config: {
    bitPixPdv: '',
    valorMaximoPixPdv: '',
  },
};

const stopLoadingFilials = combineActions(
  types.GET_FILIAL_SUCCESS,
  types.GET_FILIAL_FAIL,
  types.GET_CONFIGS_SUCCESS,
  types.GET_CONFIGS_FAIL,
);

const startLoadingFilials = combineActions(
  types.GET_FILIAL_REQUEST,
  types.GET_CONFIGS_REQUEST,
);

export const app = handleActions({
  [types.SET_DRAWER]: (state, { drawer }) => ({
    ...state,
    drawer,
  }),
  [types.SET_WIDTH]: (state, { width }) => ({
    ...state,
    width,
  }),
  [types.OPEN_MENU]: (state, { payload }) => ({
    ...state,
    menuRef: payload.menuRef,
  }),
  [types.CLOSE_MENU]: (state) => ({
    ...state,
    menuRef: null,
  }),
  [startLoadingFilials]: (state) => ({
    ...state,
    loadingFilials: true,
  }),
  [stopLoadingFilials]: (state) => ({
    ...state,
    loadingFilials: false,
  }),
  [types.GET_FILIAL_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.SET_FILIAL]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.REGISTER_SNACK]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [types.GET_CONFIGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    config: payload.config,
  }),
}, INITIAL_STATE);

const main = combineReducers({
  app,
  checkoutsAndOrders,
  order,
  checkout,
  creditRequests,
  recovery,
  report,
  discount,
  sales,
  devAcess,
  suggestion,
  clientInfo,
  clientAddresses,
  vouchersAndCoupons
});

export default main;
