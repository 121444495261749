import apiService from '../../shared/services/apiService';

export const getSolicitacoesCredito = async ({
  dataInicio,
  dataFim,
  page,
  rpp,
  orcamento,
  statusPedido,
  idVendedor,
  idCanalVendas,
  idUnidadeNegocio,
  status,
  ordenacao,
  campoOrdenacao,
  idSolicitacao,
  documento,
  nomeCliente,
  tipoPendencia,
}) => {
  const params = new URLSearchParams({
    dataInicio,
    dataFim,
    page,
    rpp,
    checkoutId: orcamento || '',
    statusPedido,
    idVendedor,
    idCanalVendas,
    idUnidadeNegocio,
    statusSolicitacaoCredito: status,
    ordenacao,
    campoOrdenacao,
    idSolicitacao,
    documento,
    nomeCliente,
    tipoPendencia,
  });

  const {
    data: { results, meta },
  } = await apiService.get(`solicitacoes-credito?${params.toString()}`);

  return {
    results,
    meta,
  };
};

export const getVendedoresSimplificado = async (idCanalVendas) => {
  const {
    data,
  } = await apiService.get(`vendedores/listar-ativos-simplificado?idCanalVenda=${idCanalVendas}&idCargos=1,2,4,7,8,11,13,15,18`);

  return data;
};

export const getComentarios = async (checkoutId, documento) => {
  const res = await apiService.get(`solicitacoes-credito/comentarios/checkout/${checkoutId}/cliente/${documento}`);

  return res.data;
};

export const enviarComentarios = async (data) => {
  const formData = new FormData();
  data.files.map(({ file }) => formData.append('arquivos', file));

  const body = {
    idVendedor: data.sellerId,
    checkoutId: data.checkoutId,
    documento: data.document,
    comentario: data.comment,
    idSolicitacao: data.creditId,
  };

  formData.append('body', JSON.stringify(body));

  const res = await apiService.post('solicitacoes-credito/comentarios', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res;
};

export const resultadoB2e = async (idSolicitacao) => {
  const res = await apiService.get(`solicitacoes-credito/${idSolicitacao}/analise-b2e`);

  return res;
};
