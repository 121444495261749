/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TablePagination } from '@material-ui/core';
import CardBar from '../../../shared/components/cardBar';

import useStyles from './styles';
import { KeyboardArrowDown } from '@material-ui/icons';

const TitlePaginationFilter = ({
  rpp,
  title,
  count,
  currentPage,
  filterOpened,
  handleChangeRpp,
  handleChangePage,
  handleToggleFilter,
}) => {
  const classes = useStyles();

  const {
    icon,
    titlePagination,
    iconFilterOpened,
    iconFilterClosed
  } = classes;

  return (
    <div className={titlePagination}>
      <CardBar title={title}>
        <TablePagination
          SelectProps={{
            IconComponent: KeyboardArrowDown,
          }}
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={count}
          rowsPerPage={rpp}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRpp}
          labelRowsPerPage="Registros:"
          />
        <IconButton onClick={handleToggleFilter}>
          <span className={`${icon} ${filterOpened ? iconFilterOpened : iconFilterClosed}`} />
        </IconButton>
      </CardBar>
    </div>
  );
};

TitlePaginationFilter.defaultProps = {
  rpp: 10,
  title: '',
  count: 0,
  currentPage: 1,
  handleChangeRpp: () => {},
  handleChangePage: () => {},
  handleToggleFilter: () => {},
};

TitlePaginationFilter.propTypes = {
  rpp: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  filterOpened: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleChangeRpp: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
};
export default TitlePaginationFilter;
