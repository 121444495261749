import apiService from '../../shared/services/apiService';

export const getFuncionalidades = async () => {
  const {
    data: { results, meta },
  } = await apiService.get('/configuracoes');

  const { totalpages } = meta;
  return {
    results,
    totalpages,
  };
};

export const putFuncionalidade = async (body) => {
  const data = await apiService.put(`/configuracoes`, {
    ...body
  });

  return {
    data,
  };
};
