import { makeStyles } from '@material-ui/core';
import { CloseIcon } from '../../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  checkoutInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',

    '& .MuiTypography-root' : {
      fontSize: '18px',
      fontWeight: 600,
      '& span': {
        color: '#FE5000'
      }
    }
  },
  checkoutInfoTotal: {
    marginBottom: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .title': {
      fontSize: 14,
      fontWeight: 600,
    },
    '& .total': {
      fontSize: 24,
      fontWeight: 600,
    } 
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: '#2A2A2A',
    marginTop: 24,
    marginBottom: 12,
  },
  divider: {
    width: '100%',
    margin: '24px 0 12px 0'
  },
  checkboxNumber: {
    marginTop: 28,
  },
  dialogTitle: {
    '& h2': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  },
  dialogTitleTypography: {
    fontSize: 20,
    fontWeight: 600,
  },
  closeTitle: {
    background: `url(${CloseIcon})`,
    display: 'flex',
    width: 32,
    height: 32,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
}));

export default useStyles;
