import { handleActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  filials: null,
  vouchersAndCoupons: null,
  isLoading: false,
};

export default handleActions({
  [Types.GET_FILIALS_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [Types.GET_FILIALS_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
    filials: null,
  }),
  [Types.GET_FILIALS_SUCCESS]: (state, { payload }) => ({
    ...state,
    filials: payload.filials,
    isLoading: false,
  }),
  [Types.GET_VOUCHERS_AND_COUPONS_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [Types.GET_VOUCHERS_AND_COUPONS_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
    vouchersAndCoupons: null,
  }),
  [Types.GET_VOUCHERS_AND_COUPONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    vouchersAndCoupons: payload.vouchersAndCoupons,
    isLoading: false,
  }),
}, INITIAL_STATE);
