import { makeStyles } from '@material-ui/core';
import { AnexoIcon } from '../../../../assets';

const useStyles = makeStyles(() => ({
  fieldContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& input': {
      display: 'none'
    }
  },
  input: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18.5px 14px',
    height: '48px',
    border: '1px solid rgba(0, 0, 0, 0.23);',
    borderRadius: '8px',

    '& .icon': {
      display: 'block',
      position: 'absolute',
      right: '10px',
      width: '20px',
      height: '20px',
      background: `url(${AnexoIcon}) no-repeat`,
    }
  },

  inputText: {
    maxWidth: 'calc(100% - 36px)',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '16px',
    color: '#2296F3',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
  disabledLabel: {
    color: '#D3D3D3',
  },
  endAdornmentIcon: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    top: 45,
    right: 0,
  },
  hintField: {
    fontSize: 12,
    color: '#7B7F82',
    fontWeight: 500,
  },
  errorField: {
    color: '#F44336',
    fontWeight: 600,
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '12px',
    marginTop: '5px',
  },
  inputExtensionErrorContainer: {
    display: 'flex',
  },
  inputExtensionErrorIcon: {
    padding: 0,
    color: '#F44336'
  }
}));

export default useStyles;
