import types from './types';
import {
  enviarComentarios,
  getComentarios,
  getSolicitacoesCredito,
  getVendedoresSimplificado,
  resultadoB2e
} from './repository';
import { getFormValues } from 'redux-form';
import { getCanalDeVendas, getUnidadeDeNegocio } from '../../shared/services/app';
import { FILTER_SALLES_CHANNEL, FILTER_SELLERS } from '../../shared/utils/creditRequests';
import resolveError from '../../shared/utils/resolveError';

export const toggleFilterAction = ({ filterOpened }) => ({
  type: types.TOGGLE_FILTER,
  payload: { filterOpened },
});

const isDocument = (value) => !isNaN(Number(normalizer(sanitize(value))));
const normalizer = (string) => (string?.trim() === "" ? "" : string?.trim().replace(/  +/g, ''));
const sanitize = (string) => (string?.replace(/[^\p{L}\p{N}\n ]/gu, ''));

export const getListCreditRequestsAction = ({
    dataInicio,
    dataFim,
    page = 1,
    rpp,
    idCanalVendas: idCanalVendasAction,
    ordenacao = '',
    campoOrdenacao = ''
  }) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CREDIT_REQUESTS_REQUEST });

    const { user: { idVendedor, idCargo} } = getState().auth.login;
    const idUnidadeNegocio = getUnidadeDeNegocio();
    const idCanalVendas = getCanalDeVendas();

    const filters = getFormValues('creditRequests/filter')(getState());

    const {
      orcamento,
      statusPedido,
      idCanalVendas: idCanalVendasFilter,
      idVendedor: idVendedorFilter,
      idSolicitacao,
      documento,
      tipoPendencia
    } = filters;

    const status = filters.status.join(',');

    const idCanalVendasRequest = () => {
      if (FILTER_SALLES_CHANNEL.includes(idCargo)) {
        return idCanalVendasFilter;
      } else {
        return idCanalVendas;
      }
    };

    const idVendedorRequest = () => {
      if (FILTER_SELLERS.includes(idCargo)) {
        if (!FILTER_SALLES_CHANNEL.includes(idCargo)) {
          return idVendedorFilter;
        } else {
          if (idCanalVendasRequest && idCanalVendasRequest !== '') {
            return idVendedorFilter;
          } else {
            return ''
          }
        }
      } else {
        return idVendedor;
      }
    }

    const { results, meta } = await getSolicitacoesCredito(
      {
        rpp,
        page,
        status,
        orcamento,
        statusPedido,
        idVendedor: idVendedorRequest(),
        idCanalVendas: idCanalVendasRequest(),
        idUnidadeNegocio,
        dataInicio,
        dataFim,
        ordenacao,
        campoOrdenacao,
        idSolicitacao,
        documento: isDocument(documento) ? normalizer(sanitize(documento)) : '',
        nomeCliente: isDocument(documento) ? '' : normalizer(sanitize(documento)),
        tipoPendencia,
      }
    );

    const res = results.map((result) => ({
      ...result,
      openShowResult: false,
      openShowComment: false,
      comments: [],
      b2eResult: {},
      loadingComments: {
        commentsList: false,
        sendComment: false,
      },
      loadingB2e : false,
    }))

    dispatch({
      type: types.GET_CREDIT_REQUESTS_SUCCESS,
      payload: {
        creditRequests: res,
        rpp: meta.rpp,
        totalpages: meta.totalpages,
        currentPage: meta.page,
        count: meta.size || 0,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CREDIT_REQUESTS_FAILURE });
  }
};

export const getVendedoresSimplificadoAction = (idCanalVendas, idCargos) => async (dispatch, getState) => {
  // dispatch({ type: types.GET_VENDEDORES_REQUEST });
  const filters = getFormValues('creditRequests/filter')(getState());

  const { user: { idGrupo } } = getState().auth.login;
  try {
    const results = await getVendedoresSimplificado({idCanalVendas});

    dispatch({
      type: types.GET_VENDEDORES_ATIVOS_SIMPLIFICADO,
      payload: {
        vendedoresSimplificado: results
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_FAILURE });
  }
};

export const setCreditRequestAction = (
  idCredit, showResult, showComment,
  comments, b2eResult, loadingCommentsList,
  loadingSendComment, loadingB2e,
) => async (dispatch, getState) => {
  try {
    const { creditRequests } = getState().main.creditRequests;

    const results = creditRequests.map((creditRequest) => {
      if (creditRequest.solicitacaoId === idCredit) {
        return ({
          ...creditRequest,
          openShowResult: showResult,
          openShowComment: showComment,
          comments: comments || [],
          b2eResult: b2eResult || {},
          loadingComments: {
            commentsList: !!loadingCommentsList,
            sendComment: !!loadingSendComment,
          },
          loadingB2e : !!loadingB2e,
        })
      } else {
        return creditRequest;
      }
    });

    dispatch({
      type: types.SET_CREDIT_REQUESTS,
      payload: {
        creditRequests: results,
      },
    });

  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

export const getCommentsAction = (data) => async (dispatch, getState) => {
  try {
    const { checkoutId, document, creditId, showComment, showResult, b2eResult, comments } = data;

    dispatch(
      setCreditRequestAction(
        creditId, showResult, showComment,
        comments, b2eResult, true,
        false
      )
    );

    const results = await getComentarios(checkoutId, document);

    dispatch(
      setCreditRequestAction(
        creditId, showResult, showComment,
        results, b2eResult, false,
        false
      )
    );

  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_COMMENTS_FAILURE });
  }
};

export const postCommentAction = (body) => async (dispatch, getState) => {
  dispatch({ type: types.POST_COMMENT_REQUEST });
  const { snack } = getState().main.app;

  try {
    const {
      checkoutId, document, creditId,
      showComment, showResult, b2eResult, comments,
    } = body;

    dispatch(
      setCreditRequestAction(
        creditId, showResult, showComment,
        comments, b2eResult, false,
        true,
      )
    );

    const results = await enviarComentarios(body);

    if(results.status === 204) {
      snack.enqueueSnackbar('Comentário enviado!', { variant: 'success' });

      dispatch({
        type: types.POST_COMMENT_SUCCESS
      });

      dispatch(getCommentsAction({
        checkoutId, document, creditId,
        showComment, showResult, b2eResult,
        comments
      }));
    } else {
      snack.enqueueSnackbar(resolveError(results.message || 'Ocorreu um erro!'), { variant: 'error' });
      dispatch({ type: types.POST_COMMENT_FAILURE });
    }

  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.POST_COMMENT_FAILURE });
  }
};

export const getResultB2eAction = (data) => async (dispatch, getState) => {
  try {
    const { creditId, showComment, showResult, b2eResult, comments } = data;

    dispatch(
      setCreditRequestAction(
        creditId, showResult, showComment,
        comments, b2eResult, false,
        true,
      )
    );

    const results = await resultadoB2e(creditId);

    dispatch(
      setCreditRequestAction(
        creditId, showResult, showComment,
        comments, results.data, false,
        false
      )
    );

  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_COMMENTS_FAILURE });
  }
};


