import React, {useEffect, useState} from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Box,
  Chip,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Icon
} from '@material-ui/core';

import {getCommentsAction, getResultB2eAction, postCommentAction, setCreditRequestAction} from '../actions';

import useStyles from '../styles';
import { toCNPJ, toCPF, toMoney, toPercent } from "../../../shared/utils/parsers";
import LoadingDark from "../../../shared/components/algolia/loadingDark/LoadingDark";
import bytesConverter from "../../../shared/utils/bytesConverter";
import moment from "moment";
import {allowedFilesExtension} from "../../../shared/utils/creditRequests";
import {getStatusMainColor, getStatusSecondaryColor} from "../../../shared/utils/statusColor";

const TableCreditRequest = ({
    setCreditRequest,
    getComments,
    row: item,
    index,
    postComment,
    getResultB2e,
    user,
	}) => {
	const classes = useStyles();

  const [comment, setComment] = useState('');
  const [files, setFiles] = useState([]);
  const [exceededSize, setExceededSize] = useState(false);
  const [exceededSizeInput, setExceededSizeInput] = useState(false);
  const [commentIsEmpty, setCommentIsEmpty] = useState(false);

  const showResultOrComment = item.openShowResult || item.openShowComment;

  const handleUrl = (url) => window.open(`${window.location.origin}/checkout/${url}`, '_blank');

  const handleChangeFiles = (e) => {
    const targetFile = e.target.files[0];

    if (targetFile) {
      if (files.length) {
        const newFile = files.filter((doc) => doc.file.name === targetFile.name);
        if(!newFile.length) {
          setFiles([...files, { file: targetFile }]);
        }
      } else {
        setFiles([...files, { file: targetFile }])
      }
    }
  }

  const handleDeleteFile = (fileName) => {
    const newFile = files.filter(({ file }) => file.name !== fileName);
    setFiles(newFile);
  }

  const openFile = (file) => {
    const imageContent = atob(file.arquivo);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = `${file.tipoConteudo}/${file.extensao}`;
    const blob = new Blob([buffer], { type });
    window.open(URL.createObjectURL(blob), '_blank');
  }

  const sendComment = async () => {
    setCommentIsEmpty(!comment);
    if((!!comment) && (files.length) && (!exceededSize) && (files.length <= 5) && (!exceededSizeInput)) {
      const obj = {
        creditId: item.solicitacaoId,
        sellerId: user.idVendedor,
        checkoutId: item.solicitacaoOrcamento,
        document: item.solicitacaoClienteDocumento,
        showResult: item.openShowResult,
        showComment: item.openShowComment,
        b2eResult : item.b2eResult,
        comments: item.comments,
        comment,
        files,
      };
      await postComment(obj);
      await clearInputs();
    }
  }

  const clearInputs = () => {
    setFiles([]);
    setComment('');
    setCommentIsEmpty(false);
  }

  const handleComment = (value) => {
    if(/^\s/.test(value)) {
      setComment(value.trim());
    } else {
      setComment(value);
    }
  }

  useEffect(() => {
    if (files) {
      const exceededFile = files.some(({file}) => {
        return bytesConverter(file.size) >= 10;
      });
      setExceededSize(exceededFile);
    }
  }, [files]);

  useEffect(() => {
    setExceededSizeInput(comment.length > 3000);
  }, [comment]);

  return (
    <>
      <TableRow
        className={`${classes.tableBodyRow} ${(showResultOrComment) && 'selectedRow'}`}
        key={`row-${index + 1}`}
      >
        <TableCell className={`${(showResultOrComment) && 'noBorder'}`}>
          <Typography variant="subtitle2" className={item.pedidoId ? classes.tableBodyCellClickableDisabled : classes.tableBodyCellClickable}>
            <Box
              onClick={() => !item.pedidoId && handleUrl(item.solicitacaoOrcamento)}
              component="span"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridGap={6}
            >
              {item.solicitacaoOrcamento && !item.pedidoId && (
                <Icon sx={{ fontSize: 9 }} fontSize="inherit">
                  open_in_new
                </Icon>
              )}
              {item.solicitacaoOrcamento || 'N/D'}
            </Box>
          </Typography>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span"  onClick={e => e.stopPropagation()}>
            {item.solicitacaoId}
          </Box>
        </TableCell>
        <TableCell className={`${classes.tableBodyCellCustomer} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span" onClick={e => e.stopPropagation()}>
            {item.solicitacaoClienteNome}
          </Box>
          <Box component="span" onClick={e => e.stopPropagation()}>
            {item.solicitacaoClienteDocumento.length === 11 ? (
              toCPF(item.solicitacaoClienteDocumento)
            ) : (
              toCNPJ(item.solicitacaoClienteDocumento)
            )}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span" onClick={e => e.stopPropagation()}>
            {item.solicitacaoVendedorNome}
          </Box>
        </TableCell>
        <TableCell className={`${classes.tableBodyCellCanalVenda} ${(showResultOrComment) && 'noBorder'}`}>
          <Box whiteSpace="nowrap" component="span" onClick={e => e.stopPropagation()}>
            {item.solicitacaoVendedorCanalVendas}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span"  onClick={e => e.stopPropagation()}>
            <Chip label={item.solicitacaoStatus} color="primary" style={
              {
                backgroundColor: getStatusMainColor(item.solicitacaoStatus),
                border: '2px solid',
                borderColor: getStatusSecondaryColor(item.solicitacaoStatus),
                color: getStatusSecondaryColor(item.solicitacaoStatus),
                fontWeight: 600,
                minWidth: '80%',
              }
            }/>
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span"  onClick={e => e.stopPropagation()}>
            {toMoney(item.solicitacaoLimiteSolicitado)}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box display="flex" justifyContent="center" alignItems="center" gridGap={4}>
            <Box component="span"  onClick={e => e.stopPropagation()}>
              {item.pedidoId  || '-'}
            </Box>
            {item.pedidoId && item.pedidoStatus && (
              <Box
                className={classes.orderStatus}
                style={{
                  background: (
                    item.pedidoStatus === 'Aprovado' ? 'green' :
                      item.pedidoStatus === 'Pendente' ? 'orange' :
                        item.pedidoStatus === 'Reprovado' ? 'red' : 'gray'
                  )
                }}
                fontSize="inherit"
              />
            )}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span">
            {item.solicitacaoEntradaPorcentagem ? toPercent(item.solicitacaoEntradaPorcentagem) : '-'}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span">
            {item.solicitacaoData}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span">
            {item.solicitacaoDataUltimaInteracao ?? '-'}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span">
            {item.solicitacaoTimePendente || '-'}
          </Box>
        </TableCell>

        <TableCell className={`${classes.tableBodyCell} ${(showResultOrComment) && 'noBorder'}`}>
          <Box component="span" className={classes.btnBox}>
            <Button
              type="button"
              disabled={item.solicitacaoStatus === 'Aberto'}
              onClick={() => {
                if (!item.openShowResult) {
                  getResultB2e({
                    creditId: item.solicitacaoId,
                    showResult: !item.openShowResult,
                    showComment: item.openShowComment,
                    b2eResult: {},
                    comments: item.comments,
                  });
                } else {
                  setCreditRequest(
                    item.solicitacaoId,
                    false,
                    item.openShowComment,
                    item.comments,
                    {},
                    false,
                    false,
                  );
                }
              }}
              className={`${item.openShowResult ? classes.hideResultBtn : classes.showResultBtn}`}
            />
            <Button
              type="button"
              onClick={() => {
                if (!item.openShowComment) {
                  getComments({
                    checkoutId: item.solicitacaoOrcamento,
                    document: item.solicitacaoClienteDocumento,
                    creditId: item.solicitacaoId,
                    showResult: item.openShowResult,
                    showComment: !item.openShowComment,
                    b2eResult : item.b2eResult,
                    comments: [],
                  });
                } else {
                  clearInputs();
                  setCreditRequest(
                    item.solicitacaoId,
                    item.openShowResult,
                    false,
                    [],
                    item.b2eResult,
                    false,
                    false,
                  );
                }
              }}
              className={`${item.openShowComment ? classes.hideCommentBtn : classes.showCommentBtn}`}
            />
          </Box>
        </TableCell>
      </TableRow>
      {
        (showResultOrComment) && (
          <TableRow className={classes.resultAndCommentRow}>
            <TableCell colSpan={13}>
              <Box className={classes.commentsBoxMain}>
                {item.openShowResult && !!Object.keys(item.b2eResult).length && (
                  <Box
                    className={classes.showResultBox}
                    style={
                      {
                        backgroundColor: getStatusMainColor(item.solicitacaoStatus),
                        borderColor: getStatusSecondaryColor(item.solicitacaoStatus),
                      }
                    }
                  >
                    <Box className={classes.b2eResultBox}>
                      <span>Motivos:</span>
                      <Box className={classes.reasonsBox}>
                        {
                          item.b2eResult.motivos.length ? (
                            item.b2eResult.motivos.map((b2e) => {
                              return (
                                <span>{`- ${b2e}`}</span>
                              )
                            })
                          ) : (
                            <span> - </span>
                          )
                        }
                      </Box>
                    </Box>
                    <Box className={classes.b2eResultBox}>
                      <span>Motivo Financeiro:</span>
                      <Box className={classes.reasonsBox}>
                        {
                          item.b2eResult.motivoFinanceiro ? (
                            <span>{item.b2eResult.motivoFinanceiro}</span>
                          ) : (
                            <span> - </span>
                          )
                        }
                      </Box>
                    </Box>
                    <Box className={classes.dateBox}>
                      <span>Data:</span>
                      <span>{item.b2eResult.data ? (moment(item.b2eResult.data).format('DD/MM/YYYY')) : 'Não informada'}</span>
                    </Box>
                  </Box>
                )}
                {
                  item.openShowComment && (
                    <Grid className={classes.commentsBox}>
                      <Box
                        className={classes.inputCommentBox}
                      >
                        <Box className={classes.labelCommentBox}>
                          <span>Escreva um comentário para o orçamento:</span>
                          <span>{`#${item.solicitacaoOrcamento}`}</span>
                        </Box>
                        <Box className={classes.labelSecondaryCommentBox}>
                            <span>
                            <strong>Atenção!</strong>
                            Caso anexar arquivos, adicione no <strong>máximo 5 </strong> arquivos de <strong>até 10MB cada</strong>.
                          </span>
                        </Box>
                        <TextField
                          classes={{ root: classes.commentInput}}
                          multiline
                          fullWidth
                          onChange={(e) => handleComment(e.target.value)}
                          variant="outlined"
                          value={comment}
                          disabled={!!item.loadingComments.sendComment}
                          error={exceededSizeInput || commentIsEmpty}
                          inputProps={{
                            maxLength: 3000,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <label
                                  htmlFor="file-upload"
                                  aria-disabled={!!item.loadingComments.sendComment || files.length >= 5}
                                  disabled={!!item.loadingComments.sendComment || files.length >= 5}
                                  className={classes.selectFileBtn}/>
                                <input
                                  id="file-upload"
                                  type="file"
                                  accept={`${allowedFilesExtension},.png,.jpg,.jpeg`}
                                  disabled={!!item.loadingComments.sendComment || files.length >= 5}
                                  value=""
                                  onChange={(e) => {
                                    if(!item.loadingComments.sendComment && files.length < 5) handleChangeFiles(e);
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {
                          commentIsEmpty && (
                            <Box className={classes.alertFileSize}>
                              <span>Campo obrigatório.</span>
                            </Box>
                          )
                        }
                        {
                          exceededSizeInput && (
                            <Box className={classes.alertFileSize}>
                              <span>Limite de caracteres atingido. Máximo: 3000 caracteres</span>
                            </Box>
                          )
                        }
                        {
                          exceededSize && (
                            <Box className={classes.alertFileSize}>
                              <span>Obs: Verifique se foi inserido um arquivo maior que 10MB.</span>
                            </Box>
                          )
                        }
                        {
                          files.length === 5 && (
                            <Box className={classes.alertFileSize}>
                              <span>Não é possível anexar mais arquivos. Limite atingido!</span>
                            </Box>
                          )
                        }
                        <Box className={classes.documentsBox}>
                          {
                            files.map(({file}) => {
                              return (
                                <Chip
                                  key={file.name}
                                  classes={{root: classes.documentChip}}
                                  label={`${file.name} (${bytesConverter(file.size).toFixed(2)} MB)`}
                                  variant="outlined"
                                  disabled={!!item.loadingComments.sendComment}
                                  onDelete={() => {
                                    if (!item.loadingComments.sendComment) handleDeleteFile(file.name);
                                  }}/>
                              )
                            })
                          }
                        </Box>
                        <Box className={classes.commentBtnBox}>
                          <Button
                          onClick={clearInputs}
                          >
                            Cancelar
                          </Button>
                          <Button
                            onClick={sendComment}
                            disabled={
                            !!item.loadingComments.sendComment ||
                              exceededSize ||
                              exceededSizeInput ||
                              (!comment && !files.length)
                          }
                          >
                            Enviar
                          </Button>
                        </Box>
                      </Box>
                      <Box className={classes.commentsListBox}>
                        {
                          !!item.comments.length && (
                            <span className={classes.commentsTitle}>Comentários:</span>
                          )
                        }

                        {
                          item.loadingComments.commentsList ? (
                            <Box className={classes.loading}>
                              <LoadingDark />
                            </Box>
                          ) : (
                            item.comments.map((creditComment) => (
                              <Box className={classes.commentAndEditBox}>
                                <Box className={classes.headerUserBox}>
                                  <Box className={classes.userBox}>
                                    <span>{creditComment.usuario[0]}</span>
                                    <span>{creditComment.usuario}</span>
                                  </Box>
                                  <Box className={classes.dateTimeAndEditBox}>
                                    <span>{creditComment.dataComentario}</span>
                                    {/*<button />*/}
                                  </Box>
                                </Box>
                                <Box className={classes.commentBoxMain}>
                                  <Box className={classes.commentBox}>
                                    <Box>
                                      {creditComment.comentario}
                                    </Box>
                                    <Box>
                                      {
                                        !!creditComment.arquivos && creditComment.arquivos.map((arquivo) => (
                                          <button
                                            onClick={() => openFile(arquivo)}>
                                            <Chip
                                              classes={{ root: `${classes.documentChip} download` }}
                                              label={arquivo.nome}
                                              variant="outlined"
                                              clickable
                                              component="a"
                                              target="_blank"
                                              deleteIcon={<Icon>download</Icon>}
                                              onDelete={() => openFile(arquivo)}
                                            />
                                          </button>
                                        ))
                                      }
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ))
                          )
                        }
                      </Box>
                    </Grid>
                  )
                }
              </Box>
            </TableCell>
          </TableRow>
        )
      }
    </>
  )
};

TableCreditRequest.propTypes = {
	row: PropTypes.instanceOf(Object).isRequired,
  setCreditRequest: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  getResultB2e: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  creditRequestComments: PropTypes.arrayOf(Object).isRequired,
  loadingComments: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = state => {
  return ({
    ...state.main.creditRequests,
    user: state.auth.login.user,
  });
}

export default connect(mapState, {
  setCreditRequest: setCreditRequestAction,
  getComments: getCommentsAction,
  postComment: postCommentAction,
  getResultB2e: getResultB2eAction,
})(TableCreditRequest);
