import React from 'react';
import * as PropTypes from 'prop-types';

import {
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import useStyles from './styles';

const TitleSelect = ({
  title,
  values,
  model,
  onChange,
  loading,
  showSelect
}) => {
  const {
    infoContent,
    selectField,
    titleTypography,
  } = useStyles();

  return (
    <div className={infoContent}>
      <Typography className={titleTypography}>{title}</Typography>
      {showSelect && (
        <FormControl>
          <Select
            renderValue={(value) => {
              if (!value) {
                return <>Selecionar empresa</>;
              }
              return <>{values.find((val) => val.codCliente === value).nome}</>;
            }}
            className={selectField}
            select
            displayEmpty
            variant='outlined'
            IconComponent={ExpandMore}
            placeholder='Selecione a filial'
            autoComplete="off"
            onChange={(event) => {
              onChange(event);
            }}
            style={{ textAlign: 'start' }}
          >
            {values
              .map((option) => (
                <MenuItem
                  value={option[model.id]}
                  key={`item-${option[model.id]}`}
                  disabled={option.disabled}
                >
                  {option[model.name]}
                </MenuItem>
              ))}
          </Select>
          {loading && <LinearProgress />}
        </FormControl>
      )}
    </div>
  );
};

TitleSelect.defaultProps = {
  onChange: () => {},
  model: {
    id: 'id',
    name: 'label',
  },
  showSelect: false,
};
TitleSelect.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  model: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  showSelect: PropTypes.bool,
};

export default TitleSelect;
