import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Box,
  Card,
  Chip,
  Grid,
  Tooltip as TooltipMUI,
} from '@material-ui/core';
import {connect, useDispatch} from 'react-redux';
import useStyles from './styles';
import {
  AddButton,
  Autocomplete,
  ContentText,
  DetailsDialog,
  Snackbar,
  Tooltip,
  VoltageProductDialog,
} from '../../../../../shared/components/algolia';
import apiService from '../../../../../shared/services/apiService';
import {getCheckoutAction, newCheckoutAction, resetSearchAction} from '../../../actions';
import {getClient, setClient} from "../../../../../shared/services/authClient";
import {Types as TypesClient} from "../../../../authClient/login";
import types from "../../../types";

const Product = ({
  className,
  product,
  userFilialId,
  snack,
  getCheckout,
  checkout,
  filialUserLinked,
  setIndexFilialUser,
  pplInfo,
  isCheckout,
  newCheckout,
  user,
  client,
  resetSearch,
  loading,
  isFisicStore,
}) => {
  const { REACT_APP_URL_IMAGES } = process.env;
  const mainUrlImages = REACT_APP_URL_IMAGES || '';
  const deliveryType = [];
  const dispatch = useDispatch();

  const [localFilial, setLocalFilial] = useState(false);
  const [outStock] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [productAvailable, setProductAvailable] = useState(false);
  const [openVoltageDialog, setOpenVoltageDialog] = useState(false);

  const [voltage, setVoltage] = useState('');
  const filialText = localFilial ? 'nesta' : 'desta';
  const stockText = outStock ? 'Sem estoque' : 'Estoque';
  const [qtyLocalFilial, setQtyLocalFilial] = useState(0);
  const [qtyRequested, setQtyRequested] = useState('1');
  const [voltageOptions, setVoltageOptions] = useState([]);
  const [guaranteesOptions, setGuaranteesOptions] = useState([]);
  const [guaranteeIdSelected, setGuaranteeIdSelected] = useState(0);
  const [voltageIdSelected, setVoltageIdSelected] = useState(0);
  const [priceEstablishment, setPriceEstablishment] = useState({});

  const [alert, setAlert] = useState({
    open: false,
    message: 'Produto adicionado ao carrinho com sucesso.',
    severity: 'success',
  });

  const classes = useStyles();
  const {
    badge,
    badgeMain,
    badgeGrid,
    brandAndVoltageBox,
    brandStyled,
    btnProductId,
    card,
    chip,
    copyIcon,
    flexColumn,
    gridCenter,
    imageHover,
    imageZoom,
    margin,
    productButton,
    productGrid,
    productId,
    productImage,
    qtyAndAdd,
    relative,
    stockTotalStyled,
    titleStyled,
    valuesGrid,
    popper,
    tooltip,
    pplIcon,
    pplContainer,
    snackbar,
  } = classes;

  const {
    available,
    brand,
    images,
    model,
    objectID,
    price,
    pma,
    shippingData,
    stockEstablishments,
    stockEstablishmentsAvailable,
    technicalSpecs,
    title,
    virtualStock,
    priceEstablishments,
  } = product;

  const createNewCheckout = (productId, qty) => {
    const checkoutInfo = {
      idVendedor: user.idVendedor,
      idFilial: filialUserLinked,
    };

    const productInfo = [{
      quantity: Number(qty) || 1,
      idSku: voltageIdSelected || +productId,
      isPPL: !!pplInfo,
      ppl: pplInfo ? pplInfo.hash : null,
      warrantyId: guaranteeIdSelected || null,
      isCompreJunto: false,
    }];

    newCheckout({checkout: checkoutInfo, product: productInfo});
  };

  const addProduct = async (productId, qty) => {
    try {
      if(!client) {
        localStorage.setItem('productInitialToLogin', JSON.stringify([{
          quantity: Number(qty) || 1,
          idSku: voltageIdSelected || +productId,
          isPPL: !!pplInfo,
          ppl: pplInfo ? pplInfo.hash : null,
          warrantyId: guaranteeIdSelected || null,
          isCompreJunto: false,
        }]));
        resetSearch(true);
      } else {
        if (!isCheckout && !client.checkoutId) {
          await createNewCheckout(productId, qty);
          setVoltageIdSelected(0);
          setGuaranteeIdSelected(0);
          setOpenVoltageDialog(false);
          setOpenDialog(false);
        } else {
          if(!isCheckout) {
            dispatch({
              type: types.CHECKOUT_LOADING,
              payload: { loading: true },
            });
          }

          const data = await apiService.post(`/carrinho/adicionar-produto`, {
            checkoutId: client.checkoutId || checkout.checkoutId,
            quantity: Number(qty),
            idFilial: filialUserLinked,
            idSku: voltageIdSelected ? voltageIdSelected : +productId,
            warrantyId: guaranteeIdSelected ? guaranteeIdSelected : null,
            ppl: pplInfo ? pplInfo.hash : null,
            isCompreJunto: false,
            isPPL: !!pplInfo,
            userId: user.idVendedor,
            cep: checkout.customer?.address?.zipCode,
            customerId: client.codCliente,
            isCatalogo: false,
          }).then((response) => response.data.results.response);

          if (data) {
            setAlert({...alert, open: true});
            setVoltageIdSelected(0);
            setGuaranteeIdSelected(0);
            setOpenVoltageDialog(false);
            setOpenDialog(false);
            if (isCheckout) {
              getCheckout({ idCheckout: checkout.checkoutId, startRequest: true, calculateTax: false });
            }

           if(!isCheckout) {
             setClient({
               ...client,
               qtyProducts: Number(client.qtyProducts) + Number(qty),
             });

             dispatch({
               type: TypesClient.SET_CLIENT_SUCCESS,
               payload: {
                 client: {
                   ...client,
                   qtyProducts: Number(client.qtyProducts) + Number(qty),
                 },
                 clients: null,
               },
             });

             dispatch({
               type: types.CHECKOUT_LOADING,
               payload: { loading: false },
             });
           }
          }
        }
      }
    } catch (e) {
      setAlert({
        open: true,
        message: e,
        severity: 'error',
      })
    }
  };

  const handleVoltage = (voltage) => {
    setVoltage(voltage);
  };

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  const handleStockTotal = (Object.keys(stockEstablishments).length
    ? (Object.values(stockEstablishments).reduce((acum, value) => acum + value)) : 0);

  const handleLocalFilial = () => {
    const filial = Array(stockEstablishments).map((value) => value[userFilialId]);
    const qty = filial[0] || 0;
    setLocalFilial(!!qty);
    setQtyLocalFilial(qty);
  };

  const handlePriceEstablishments = () => {
    if (filialUserLinked) {
      const index = Number(
        Object.keys(priceEstablishments).find(
          (key) => priceEstablishments[key].branchId === Number(filialUserLinked),
        ),
      ) || null;

      if (priceEstablishments[index]) {
        const { term, name } = priceEstablishments[index];

        setIndexFilialUser(index);
        setPriceEstablishment({
          ...price,
          name,
          term,
        });
      } else {
        setPriceEstablishment({
          ...price,
          name: '',
        });
      }
    } else {
      setPriceEstablishment({
        ...price,
        name: '',
      });
    }
  };

  const handleProductAvailable = () => setProductAvailable(available);

  const handleVoltageInitial = () => {
    setVoltage(technicalSpecs.voltage || '');
  };

  useEffect(() => {
    handleProductAvailable();
    handleVoltageInitial();
    handlePriceEstablishments();
  }, [filialUserLinked]);

  useEffect(() => {
    handleLocalFilial();
  }, [userFilialId]);

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  if (productAvailable) {
    if (stockEstablishmentsAvailable.some((item) => item === 'CrossDocking') || virtualStock) {
      deliveryType.push({
        textPrimary: virtualStock ? 'Estoque virtual' : 'Produto crossdocking',
        textSecondary: `Produto disponível após <b>${technicalSpecs.virtualDays} dias</b>`,
        name: `<b>${virtualStock ? 'VT' : 'XD'}</b> | Disponível após <b>${technicalSpecs.virtualDays}</b> dias`,
        color: 'colorYellow',
      });
    }
    if (shippingData.freeShipping) {
      deliveryType.push({
        textPrimary: 'Frete grátis',
        textSecondary: 'Para todo o Brasil',
        name: 'FG - BR',
        color: 'colorGreen',
      });
    }
    if (shippingData.expressDelivery) {
      deliveryType.push({
        textPrimary: 'Entrega expressa',
        name: 'EE',
        color: 'colorBlue',
      });
    }
  }
  const handleVoltageModal = async () => {
    if (technicalSpecs.voltage) {
      await getDetailProduct(objectID, technicalSpecs.voltage);
    } else await addProduct(objectID, qtyRequested);
  };

  const getDetailProduct = async (idSkus) => {
    try {
      const data = await apiService.get(`/produtos/${idSkus}/?qtd=1`).then((response) => response.data.results);
      if (data === null) {
        snack.enqueueSnackbar(`problema com o produto ${idSkus}`, { variant: 'error' });
      }
      if (data.tensao.length) {
        const resultVoltage = data.tensao.filter(({ tensao }) => tensao.match(technicalSpecs.voltage));
        const res = resultVoltage.length ? resultVoltage[0] : data.tensao[0];

        setVoltageOptions([{
          ...res,
          selected: true,
        }]);
        setVoltageIdSelected(res.id);
        setGuaranteesOptions(data.garantias);
        setOpenVoltageDialog(true);
      } else await addProduct(objectID, qtyRequested);
    } catch (e) {
      snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    }
  };

  return (
    <Box>
      {!!pplInfo && (
        <div className={pplContainer}>
          <span className={pplIcon} />
        </div>
      )}
      <Card className={`${className} ${card} ${(productAvailable && localFilial) && 'localFilial'} ${!productAvailable && 'disabled'}`} key={objectID}>
        <Grid className={productGrid}>
          <Grid className={valuesGrid}>
            <span className={margin}>{(product.rankingInfo || product.rankingData).margin.toFixed(2)}</span>
            <TooltipMUI
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltip}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              classes={{ popper, tooltip }}
              title="Código copiado"
              placement="top"
            >
              <button type="button" className={btnProductId} onClick={() => copyToClipboard(objectID)}>
                <div className={productId}>
                  {`| ${objectID}`}
                </div>
                <div className={copyIcon} />
              </button>
            </TooltipMUI>
          </Grid>
          <span className={stockTotalStyled}>
            {`${handleStockTotal.toLocaleString('pt-br')} un.`}
          </span>
        </Grid>

        <Grid className={`${gridCenter} ${relative}`}>
          {productAvailable ? (
              <button
                type="button"
                className={`${productButton} ${gridCenter} ${relative}`}
                onClick={() => setOpenDialog(true)}
              >
                <img
                  className={productImage}
                  src={`${mainUrlImages}${images[0]}`}
                  alt={title}
                />
                <div className={imageHover}>
                  <div className={imageZoom} />
                </div>
              </button>
          ) : (
            <button
              type="button"
              className={`${productButton} ${gridCenter} ${relative}`}
              onClick={() => setOpenDialog(true)}
            >
              <img
                className={`${productImage} disabled`}
                src={`${mainUrlImages}${images[0]}`}
                alt={title}
              />
              <div className={`${imageHover} disabled`}>
                <div className={`${imageZoom} disabled`} />
              </div>
            </button>
          )}
        </Grid>

        <div className={badgeMain}>
          <Grid className={badgeGrid}>
            {!productAvailable ? (
              <Badge className={`${badge} colorDisabled`}>
                Indisponível
              </Badge>
            ) : (
              deliveryType.map((
                {
                  name,
                  textPrimary,
                  textSecondary,
                  color,
                },
              ) => (
                <Tooltip
                  key={name}
                  textPrimary={textPrimary}
                  textSecondary={!!textSecondary && textSecondary}
                >
                  <Badge className={`${badge} ${color}`}>
                    <div dangerouslySetInnerHTML={{ __html: name }} />
                  </Badge>
                </Tooltip>
              ))
            )}
          </Grid>
        </div>

        <Grid>
          <h2 className={titleStyled}>{`${title} ${model || ''}`}</h2>
          <Box className={brandAndVoltageBox}>
            <Badge>
              <h3 className={`${brandStyled} ${!productAvailable && 'disabled'}`}>{brand.name}</h3>
            </Badge>
            <div className={flexColumn}>
              {technicalSpecs.voltage && (
                <Chip
                  size="small"
                  className={`${chip} ${voltage && 'selected'}`}
                  label={technicalSpecs.voltage}
                  key={objectID}
                  onClick={() => (!voltage && handleVoltage(technicalSpecs.voltage))}
                  clickable={!productAvailable}
                  disabled={!productAvailable}
                />
              )}
            </div>
          </Box>
        </Grid>
        <ContentText
          priceProduct
          isFisicStore={isFisicStore}
          data={{
            ...priceEstablishment,
            pma,
            available: productAvailable,
            ppl: pplInfo,
          }}
        />
        <Grid className={`${qtyAndAdd} ${(!productAvailable || loading) && 'disabled'}`}>
          <Autocomplete
            disabled={!productAvailable}
            value={qtyRequested}
            setValue={setQtyRequested}
            onClickDisabled={(!productAvailable || loading)}
          />
          {
            productAvailable && !loading ? (
              <Tooltip
                textSecondary={`${stockText} ${filialText} filial`}
              >
                <span>
                  <AddButton
                    localFilial={localFilial}
                    qty={qtyLocalFilial}
                    onClick={(!productAvailable || loading) ?
                      () => {} : handleVoltageModal}
                    disabled={!productAvailable || loading}
                  />
                </span>
              </Tooltip>
            ) : (
              <span>
                <AddButton
                  localFilial={localFilial}
                  qty={qtyLocalFilial}
                  disabled={!productAvailable || loading}
                />
              </span>
            )
          }
        </Grid>
        <VoltageProductDialog
          open={openVoltageDialog}
          setOpen={setOpenVoltageDialog}
          addProduct={() => addProduct(objectID, qtyRequested)}
          voltageOptions={voltageOptions}
          guaranteesOptions={guaranteesOptions}
          handleGuaranteeId={{ guaranteeIdSelected, setGuaranteeIdSelected }}
        />
        <DetailsDialog
          setOpen={setOpenDialog}
          open={openDialog}
          product={product}
          pplInfo={pplInfo}
          priceEstablishment={priceEstablishment}
          userFilialId={userFilialId}
          data={{
            stockText,
            filialText,
            localFilial,
            qtyLocalFilial,
          }}
          addProduct={handleVoltageModal}
          qtyProductRequested={qtyRequested}
          setQtyProductRequested={setQtyRequested}
          isFisicStore={isFisicStore}
          productAvailable={productAvailable}
        />
        <Snackbar
          className={snackbar}
          alertOptions={alert}
          setAlertOptions={setAlert}
        />
      </Card>
    </Box>
  );
};

Product.defaultProps = {
  className: '',
  userFilialId: 0,
  snack: null,
  pplInfo: null,
  isCheckout: false,
  loading: false,
  isFisicStore: false,
};

Product.propTypes = {
  className: PropTypes.string,
  product: PropTypes.instanceOf(Object).isRequired,
  userFilialId: PropTypes.number,
  addProduct: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object),
  filialUserLinked: PropTypes.string.isRequired,
  pplInfo: PropTypes.instanceOf(Object),
  isCheckout: PropTypes.bool,
  newCheckout: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  resetSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isFisicStore: PropTypes.bool,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout, loading } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    user: state.auth.login.user,
    client: getClient(),
    checkout,
    loading,
    snack,
  });
};

export default connect(mapState, {
  getCheckout: getCheckoutAction,
  newCheckout: newCheckoutAction,
  resetSearch: resetSearchAction,
})(Product);
