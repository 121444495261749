import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fieldContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  input: {
 
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },

    '& .MuiInputBase-root': {
      borderRadius: 8,
      borderColor: '#CFCFD4',
      fontWeight: 600,
      lineHeight: '20px',
      height: 48,
      fontSize: 16,
      color: '#242424',
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FE5000',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: 12,
      fontWeight: 600,
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiRadio-root': {
        paddingTop: 4,
        paddingBottom: 4,
      },
      '& .MuiTypography-root': {
        fontSize: 14,
        fontWeight: 500,
        color: '#7B7F82',
      },
    },
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
  activeLabel: {
    color: '#FE5000',
  },
  disabledStyle: {
    '& .MuiOutlinedInput-root ': {
      background: '#F8F9FA'
    }
  },
  endAdornmentIcon: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    top: 45,
    right: 0,
  },
  hintField: {
    fontSize: 12,
    color: '#7B7F82',
    fontWeight: 500,
  },
}));

export default useStyles;
