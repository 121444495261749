import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  creditRequestTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'rgb(26, 28, 38)',
  },
  creditRequestStatusText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '500',
    color: 'rgb(26, 28, 38)',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      marginLeft: 4,
      cursor: 'pointer',
      color: '#FE5000',
      fontWeight: 600
    }
  },
  expansionPanel: {
    padding: 0,
    minHeight: 40,
    height: 40
  },
  launchIcon: {
    fontSize: 12,
  },
}));

export default useStyles;
