// eslint-disable-next-line import/no-cycle
import Types from './types';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import apiService from '../../../../shared/services/apiService';

const getVoucherAndCouponsAction = (codCliente, listarTodos = true) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_VOUCHERS_AND_COUPONS_REQUEST });

  try {
    const response = await apiService.get(`/vales-e-cupons/cliente/${codCliente}/listar-todos/${listarTodos}`);
    const {data: {content: content}} = response;

    dispatch({
      type: Types.GET_VOUCHERS_AND_COUPONS_SUCCESS,
      payload: { vouchersAndCoupons: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_VOUCHERS_AND_COUPONS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};


const getListFilialsAction = (codMatriz) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_FILIALS_REQUEST });

  try {
    const response = await apiService.get(`/clientes/filiais/listar-filiais/${codMatriz}/codcliente-e-nome`);
    const {data: {content: content}} = response;

    dispatch({
      type: Types.GET_FILIALS_SUCCESS,
      payload: { filials: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_FILIALS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

export {
  getVoucherAndCouponsAction,
  getListFilialsAction
};
