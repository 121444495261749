import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    height: 80,
    border: '1px solid #CFCFD4',
    '& .MuiDivider-root': {
      marginTop: -2,
    },
    '&.search': {
      [breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    [breakpoints.down('sm')]: {
      backgroundColor: '#FF5225',
      border: 'none',
      color: '#ffff',
    },
  },
  searchInput: {
    width: '85%',
  },
  option: {
    backgroundColor: '#FFFFFF',
    cursor: 'auto',
    paddingRight: 0,
    fontSize: 14,
    overflow: 'hidden',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderBottomColor: '#E3E3E7',
    borderTopColor: '#E3E3E7',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  toolBarOrange: {
    paddingRight: 14,
    '& > .left-container': {
      paddingLeft: 64,
      width: 60,
      display: 'flex',
      alignItems: 'center',
      height: 80,
      backgroundColor: '#FF5225',
      border: 'none',
      [breakpoints.down('sm')]: {
        borderRight: 'none',
        height: 'auto',
        width: 'auto',
      },

      '& img': {
        maxWidth: 180,
        [breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    '& > .title': {
      marginLeft: 16,
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > .right-container': {
      marginLeft: 'auto',
      display: 'flex',
    },
    '&.search': {
      paddingRight: 45,

      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  },
  toolBarWhite: {
    paddingRight: 14,
    minHeight: 80,
    '& > .left-container': {
      paddingLeft: 5,
      width: 60,
      display: 'flex',
      alignItems: 'center',
      height: 80,
      backgroundColor: '#FFFFFF',
      border: 'none',
      [breakpoints.down('sm')]: {
        borderRight: 'none',
        height: 'auto',
        width: 'auto',
      },
      '& img': {
        maxWidth: 180,
        [breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    '& > .title': {
      marginLeft: 16,
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > .right-container': {
      marginLeft: 'auto',
      display: 'flex',
    },
    '&.search': {
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    '& .button-header': {
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  button: {
    padding: 0,
    paddingLeft: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  version: {
    position: 'absolute',
    color: palette.primary.main,
    bottom: -2,
    right: 0,
    opacity: 0.6,
    fontSize: 10,
  },
  boxSelect: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',

  },
  divImageDescription: {
    height: 100,
    width: '55%',
    fontSize: 12,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      width: '45%',
      fontSize: 9,
    },
  },
  divQuantity: {
    height: 100,
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      width: '26%',
    },
  },
  divValue: {
    height: 100,
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    [breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  divButton: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '20%',
    },
  },
  autoComplete: {
    padding: 10,
    paddingLeft: 100,
    paddingRight: 100,
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: 15,
    },
    [breakpoints.down('sm')]: {
      position: 'absolute',
      top: 68,
      left: 0,
      paddingLeft: 10,
      paddingRight: 13,
      width: '100%',
      background: '#ffffff',
    },
  },
  buttonAdicionar: {
    backgroundColor: '#21AD34',
    height: 35,
    width: 100,
    fontSize: 14,
    color: '#FFFF',
    '&:hover': {
      backgroundColor: '#37B548',
    },
    '&:disabled': {
      backgroundColor: '#CFCFD4',
    },
    [breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  iconQtd: {
    color: '#FF5225',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  productId: {
    maxWidth: 110,
    maxHeight: 16,
    '& span': {
      height: 8,
      maxWidth: 80,
      color: palette.primary.main,
      fontSize: 11,
      textTransform: 'none',
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
  },
  voltsId: {
    borderRadius: 5,
    height: 16,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FF5225',
    '& span': {
      color: '#ffff',
      fontSize: 8,
      fontWeight: 600,
    },
  },
  dialogTensao: {
    '&.MuiDialog-paperWidthSm': {
      maxWidth: 400,
    },
  },
  buttonAdd: {
    color: '#FFFF',
    backgroundColor: '#21AD34',
    '&:disabled': {
      backgroundColor: '#EDEDED',
    },
    '&:hover': {
      backgroundColor: '#37B548',
    },
  },
  valueSelect: {
    fontWeight: 600,
    fontSize: 12,
  },
  valueSelectAvista: {
    fontWeight: 400,
    fontSize: 12,
  },
  root: {
    '& .MuiAutocomplete-listbox': {
      '& li:nth-child(even)': { backgroundColor: '#f92' },
      '& li:nth-child(odd)': { backgroundColor: '#f21' },
    },
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,

    [breakpoints.down('sm')]: {
      justifyContent: 'end',
      width: 'calc(100% - -50px)',
    },
  },
}));

export default useStyles;
